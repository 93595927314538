@font-face {
  font-family: 'pokemon_gb';
  src:url('../../public/resources/fonts/PokemonGB/pokemongb-raeo-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

*, *::before, *::after {
  box-sizing: border-box;
  font-family: 'pokemon_gb', Arial;
  color: #33321a
}

/* #root {
  min-height: -webkit-fill-available;
  min-height: 100vh;
} */

body {
  /* min-height: -webkit-fill-available;
  min-height: 100vh; */
  width: 100%;
  margin: 0;
  font-size: clamp(.25rem, 1.5vw, 1.5rem);
}

.App {
  /* height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.main-header {
  padding: .5rem;
  -webkit-padding-end: .25rem;
  -webkit-padding-start: .25rem;
  border-bottom: 1px solid rgb(210, 210, 210);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 500px) {
  .main-header {
    padding: .5rem 0;
  }
}

.logo {
  width: 150px;
}

.menu-container {
  border: 3px double black;
  border-radius: 3px;
  background-color: white;
  padding: .5em 1em;
  z-index: 1;
  position: absolute;
  top: calc(100% + 3px);
  right: 3px;
}

.menu-button {
  border: none;
  background-color: inherit;
  font-size: 2rem;
}

.help-button {
  border: none;
  background-color: inherit;
  font-size: clamp(1rem, 5vw, 1.5rem);
}

.main-nav {
  display: none;
}

.main-nav ul {
  margin: 0;
  padding: 0;
}


.main-nav li:hover, .main-nav a:focus {
  color: rgb(0,94,98);
  text-decoration: none;
}

.main-nav li {
  list-style: none;
  padding: .25em 0;
  cursor: pointer;
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  font-size: .75rem;
}

.right-container {
  display: none;
  width: 97.5%;
  max-width: 300px;
  min-height: 50px;
  max-height: 70vh;
  overflow-y: scroll;
}

.menu-container .exit-button {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-radius: 0 0 0 3px;
  color: black;
  height: 25px;
  width: 25px;
  user-select: none;
  cursor: pointer;
}

.pokedex-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pokedex-item h4, .pokedex-item p {
  font-size: clamp(.5rem, 4vw, .75rem);
  padding: 0;
  margin: 0;
}

.pokedex-item img {
  width: 30%;
}

.total-stats-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1em;
}

.stat-item {
  flex: 1;
  text-align: center;
}

.total-stats-container h4 {
  font-size: 1rem;
}

.total-stats-container p {
  font-size: .5rem;
}

.guess-bar-row {
  display: flex;
  gap: .5em;
  align-items: center;
}

.guess-bar-row p {
  margin: 0;
}

.bar-area {
  text-align: end;
  color: white;
  font-size: .5rem;
  padding: 2px 5px;
  min-width: 1rem;
}

.how-to-modal .modal-dialog-centered {
  max-width: 370px;
}

.how-to-modal .h4, .how-to-modal p, .how-to-modal h4 {
  font-family: Arial;
  text-align: left;
}

.how-to-modal strong {
  font-family: Arial;
  font-weight: 900;
}

.how-to-modal .guess-row {
  grid-template-columns: repeat(12, 25px);
}

.keyboard {
  display: grid;
  grid-template-columns: repeat(20, minmax(.5rem, 1.5rem));
  grid-auto-rows: 3em;
  gap: .25em;
  justify-content: center;
  width: 100%;
  padding: .5em;
}

.key {
  grid-column: span 2;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 3px;
  user-select: none;
  background-color: rgb(220,220,220);
  border: 2px solid rgb(120, 124, 126);
  font-weight: bold;
  font-size: clamp(.25rem, 2.5vw, 1rem);
}

.key:hover, .key:focus {
  opacity: 80%;
}

.key.wide {
  grid-column: span 3;
}

.enter-key {
  font-size: clamp(.25rem, 1.5vw, .75rem);
  padding: 1px;
}

.wrong {
  background-color: rgb(120, 124, 126);
  color: white;
}

.wrong-spot {
  background-color: rgb(201, 180, 88);
  color: white;
}

.correct {
  background-color: rgb(106, 170, 100);
  color: white;
}

.guess-grid {
  display: grid;
  grid-template-rows: repeat(8, 6vh);
  gap: .2em;
  margin: 0 auto;
  width: 97.5%;
  padding: 1.5em;
  justify-content: center;
  font-size: clamp(.5rem, 3.5vmin, 1.2rem);
}

.guess-row {
  display: grid;
  grid-template-columns: repeat(12, 3vw);
  gap: .2em
}

@media (orientation: portrait) {
  .guess-grid {
    grid-template-rows: repeat(8, 6vh);
  }

  .guess-row {
    grid-template-columns: repeat(12, 7vw)
  }
}

.guess-tile {
  border: 1px solid rgb(210, 210, 210);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shake  {
  animation: shake 250ms ease-in-out;
}

@keyframes shake {
  10% {
    transform: translateX(-5%);
  }
  30% {
    transform: translateX(5%);
  }
  50% {
    transform: translateX(-7.5%);
  }
  70% {
    transform: translateX(7.5%);
  }
  90% {
    transform: translateX(-5%);
  }
}

.error-message {
  display: none;
  opacity: 0;
  position: fixed;
  top: 10vh;
  right: 50vw;
  transform: translate(50%);
  background-color:rgb(120, 124, 126);
  color:white;
  border: 4px double rgb(210, 210, 210);
  border-radius: 3px;
  padding: 5px 10px;
  text-align: center;
  font-size: clamp(.5rem, 2vw, 1rem);
  line-height: 1.5em;
}

.show-error {
  display: block;
  animation: fade-out 1500ms ease-in-out;
}

@keyframes fade-out {
  20% {
    opacity: 100;
  }

  90% {
    opacity: 0;
  }
}

.modal-body {
  text-align: center;
}

.slide-away {
  opacity: 0;
  animation: slide-away 2500ms ease-out;
}

@keyframes slide-away {
  
  30% {
    opacity: 80%;
  }
  
  60% {
    transform: translateX(0);
    opacity: 100%;
  }

  90% {
    transform: translateX(90%);
    opacity: 0%;
  }
}

.show-item {
  display: block;
}